import {FunctionComponent} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

import {DateCell, MessageCell} from "./components";

export type NewsDialogProps = {
    isOpened: boolean,
    onClose: () => void,
}

export const NewsDialog: FunctionComponent<NewsDialogProps> = ({isOpened, onClose}) => (
    <Dialog
        open={isOpened}
        onClose={onClose}
    >
        <DialogTitle>
            Novinky
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <p>Zde je historie některých vylepšení.</p>
                <br/>
                <table style={{borderSpacing: "0 8px"}}>
                    <tbody>
                    <tr>
                        <DateCell>02.03.2025</DateCell>
                        <MessageCell>
                            Do kategorie Běh nově započítáváme běžky. V aplikaci strava se jedná o typ aktivity Nordic Ski.<br/>
                        </MessageCell>
                    </tr>
                    <tr>
                        <DateCell>11.09.2023</DateCell>
                        <MessageCell>
                            Přidán odznak za poměr nastoupaných metrů vůči vzdálenosti a odznak pro e-bikery.<br/>
                            Opraven výpočet času, místo Elapsed time použito nyní Moving time.<br/>
                            Nové tlačítko pro propojení se Stravou, aby splňovalo podmínky pro použití Strava API.
                        </MessageCell>
                    </tr>
                    <tr>
                        <DateCell>12.03.2023</DateCell>
                        <MessageCell>
                            Přidáno zobrazení času a výškových metrů.<br/>
                            Přidán přepočet km/min na Kč v kategoriích.<br/>
                            Přidána aktuální hodnota daru v Kč pod titulek.<br/>
                            Strava sport type Handcycle zařazen do kategorie Kolo.<br/>
                            Aktualizace dat ze Stravy každou celou hodinu v čase 8–24 hod.<br/>
                            Přidány tooltipy zobrazující přesná čísla výsledků.<br/>
                            Změna layoutu aplikace a další drobná vylepšení.
                        </MessageCell>
                    </tr>
                    <tr>
                        <DateCell>24.04.2022</DateCell>
                        <MessageCell>U jména přidán tooltip se všemi disciplínami.</MessageCell>
                    </tr>
                    <tr>
                        <DateCell>17.02.2022</DateCell>
                        <MessageCell>Přidán součet kilometrů do názvu stránky.</MessageCell>
                    </tr>
                    <tr>
                        <DateCell>11.02.2022</DateCell>
                        <MessageCell>Nově přidaní MoroSystémáci mají za svým jménem emoji 🆕.</MessageCell>
                    </tr>
                    <tr>
                        <DateCell>10.02.2022</DateCell>
                        <MessageCell>Jménům přidán odkaz do Strava profilu.</MessageCell>
                    </tr>
                    <tr>
                        <DateCell>10.02.2022</DateCell>
                        <MessageCell>Údajům v minutách přidán popisek s přepočtem do hodin.</MessageCell>
                    </tr>
                    <tr>
                        <DateCell>01.02.2022</DateCell>
                        <MessageCell>Spuštění výzvy.</MessageCell>
                    </tr>
                    </tbody>
                </table>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
);
