import { ReactNode } from "react";

interface DateCellProps {
    children: ReactNode;
}

export const DateCell = ({ children }: DateCellProps) => (
    <td style={{ whiteSpace: "nowrap", verticalAlign: "text-top", padding: 0, paddingRight: 10 }}>
        {children}
    </td>
);
