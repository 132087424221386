import { FunctionComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export type UpdateDialogProps = {
    isOpened: boolean,
    onClose: () => void,
}

export const UpdateDialog: FunctionComponent<UpdateDialogProps> = ({ isOpened, onClose }) => (
    <Dialog
        open={isOpened}
        onClose={onClose}
    >
        <DialogTitle >
            Aktualizace dat
        </DialogTitle>
        <DialogContent>
            <DialogContentText color="#000">
                Proč tak staré údaje? Strava umožňuje pro čtení dat max. 300 dotazů za 15 min a 3 000 dotazů denně. Na každého uživatele je nutný alespoň
                jeden dotaz. Se započtením aktualizace přihlašovacích tokenů a předchozích měsíců je možných jen několik aktualizací denně.<br/>
                Nyní se aktualizace dat za aktuální měsíc provádí každou celou hodinu v čase od 8 hod. do půlnoci.<br/>
                Aktualizace dat za minulý měsíc probíhá jednou denně, vždy na konci dne.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
);
