import React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";

import { Router } from "./Router";
import "./index.css";

const container = document.getElementById("root");

if (!container) {
    throw new Error("Element with ID 'root' not found");
}

const root = createRoot(container);
root.render(
    <React.StrictMode>
        <CssBaseline />
        <Router />
    </React.StrictMode>
);

reportWebVitals();
