import { FunctionComponent } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { App } from "./App";
import { Error, Success } from "./components";

export const Router: FunctionComponent = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/problem" element={<Error />} />
            <Route path="/success/added" element={<Success />} />
            <Route path="/" element={<App />} />
        </Routes>
    </BrowserRouter>
);
