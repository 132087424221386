import { ReactNode, FC } from "react";

type Props = {
    children?: ReactNode
};

export const MessageCell: FC<Props> = ({ children }) => (
    <td style={{ lineHeight: 1.2 }}>
        {children}
    </td>
);
