import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { PRIMARY_COLOR } from "../../utils";

export const BackLink: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <a
            href="#"
            onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}
            style={{ color: PRIMARY_COLOR }}
        >
            Zpět
        </a>
    );
};
