import { FunctionComponent } from "react";

import {UNBREAKING_SPACE} from "../utils";
import { Spacer } from "./Spacer";
import {Tooltip} from "@mui/material";

export const ContactFooter: FunctionComponent = () => (
    <div>
        S láskou
        {UNBREAKING_SPACE}
        <Tooltip title={`Pavel Pospíšil + Honza B. + Martin H. + Ondra U. + Vojta Š.`} followCursor={true}>
            <span>
                MoroKilometryTým
            </span>
        </Tooltip>
        <Spacer />
    </div>
);
